<template>
    <section class="section__container container container_active separator-when-scrolling pfull__12">
        <div class="card pfull__16 mb__12">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__link"/></span> </span>
                <h5 class="parameter__label">{{ $t('Unit.locator.Link') }}</h5>
                <p class="parameter__value">
                    http://50.57.9.66:85/locator?token=1172b7923cea6c0b491e6caa79bf5591f09b4a90&lang=en</p>
            </div>

            <div class="horizontal-line">
                <button class="button button_view_base button_theme_neutral button_width_100p mr__8">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy_sm"/></span> </span>
                    {{ $t('buttons.Copy') }}
                </button>
                <button class="button button_view_base button_theme_neutral button_width_100p mr__8">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__follow_sm"/></span> </span>
                    {{ $t('buttons.Follow') }}
                </button>
                <button class="button button_view_base button_theme_neutral button_width_100p is_danger">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete_sm"/></span> </span>
                    {{ $t('buttons.Delete') }}
                </button>
            </div>
        </div>
        <div class="card card_type_parameters-2-columns card_width_400 pfull__16 mb__12">
            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__number"/></span> </span>
                <h5 class="parameter__label">{{ $t('Unit.locator.Number of clicks') }}</h5>
                <p class="parameter__value">12</p>
            </div>
            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__clock"/></span> </span>
                <h5 class="parameter__label">{{ $t('Unit.locator.last click') }}</h5>
                <p class="parameter__value">Nov 14, 12:00 AM</p>
            </div>
        </div>
        <div class="field-group field-group_width_400 mb__12">
            <label class="field-group__label" for="locator__valid-until">{{ $t('Unit.locator.Valid until') }}</label>
            <input class="field-group__input" type="text" id="locator__valid-until" name="locator__valid-until" value="Nov 21, 12:00 AM">
        </div>
        <div class="field-group field-group_width_400 mb__12">
            <label class="field-group__label" for="locator__description">{{ $t('Unit.locator.Description') }}</label>
            <textarea class="field-group__textarea" id="locator__description" name="locator__description">
                Text description Text description Text description Text description Text description Text description Text description Text description
            </textarea>
        </div>
        <button class="button button_view_base button_theme_white mb__48">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add_sm"/></span> </span>
            {{ ('Add locator link') }}
        </button>
    </section>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitEdit_Locator',
        props: [
            'unitId',
        ],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
        },
        methods: {
            ...mapActions([
            ]),
        },
        mounted() {
        }
    }
</script>